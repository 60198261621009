export interface iExcelOfferToImport {
  'Nº Oferta': string;
  'Peticion de oferta': string | null;
  'Nombre Cliente': string | null;
  'Sigla cliente': string | null;
  'Descripcion oferta': string | null;
  'Version documento': number | null;
  'Sigla preparado': string | null;
  'Fecha preparado': string | null;
  'Sigla revisado': string | null;
  'Fecha revisado': string | null;
  'Sigla aprobado': string | null;
  'Fecha aprobado': string | null;
  [key: string]: string | number | null;
}

export class IExcelOfferToImport {
  private static isValidExcelOffer(offer: any): offer is iExcelOfferToImport {
    const requiredKeys = ['Nº Oferta'];

    for (const key of requiredKeys) {
      if (!(key in offer)) {
        return false;
      }
    }
    let isValid = true;
    Object.keys(offer).forEach((offerKey) => {
      if (
        offerKey === 'Nombre componente' ||
        offerKey === 'Seccion' ||
        offerKey === 'nombre cca'
      ) {
        isValid = false;
        return;
      }
    });
    return isValid;
  }

  public static validateExcelData(data: any[]): boolean {
    return data.every(this.isValidExcelOffer);
  }
}
