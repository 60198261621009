import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import {
  iExcelBody,
  iExcelBodyToSend,
} from '../../Shared/Class/ViewComponentClass';
import { iBase64File } from '../../Shared/Interfaces/Utils/iBase64File';
import {
  iExcelBodyToImport,
  Section,
} from '../../Popups/Add-Components-Bulk-Popup/Add-Components-Bulk-Popup.component';
import { iDate } from '@quasar-dynamics/ngx-qd-design-system';
import {
  IExcelOfferToImport,
  iExcelOfferToImport,
} from '../../Shared/Interfaces/Utils/iExcelOfferToImport';
import { iExcelOfferImportToSend } from '../../Shared/Interfaces/Utils/iExcelOfferImportToSend';
import {
  IExcelOfferComponents,
  iExcelOfferComponents,
} from '../../Shared/Interfaces/Utils/iExcelOfferComponents';
import { StaticDataHandlerService } from './StaticDataHandler.service';
import {
  IExcelOfferComponentsCharacteristics,
  iExcelOfferComponentsCharacteristics,
} from '../../Shared/Interfaces/Utils/iExcelOfferComponentsCharacteristics';
import { iExcelOfferToExport } from '../../Shared/Interfaces/Utils/iExcelOfferToExport';
import { iExcelOfferToExportHeaders } from '../../Shared/Interfaces/Utils/iExcelOfferToExportHeaders';
import { iExcelOfferComponentsToExport } from '../../Shared/Interfaces/Utils/iExcelOfferComponentsToExport';
import { iExcelOfferComponentsToExportHeaders } from '../../Shared/Interfaces/Utils/iExcelOfferComponentsToExportHeaders';
import { iExcelOfferComponentsCharacteristicsToExport } from '../../Shared/Interfaces/Utils/iExcelOfferComponentsCharacteristicsToExport';
import { iExcelOfferComponentsCharacteristicsToExportHeaders } from '../../Shared/Interfaces/Utils/iExcelOfferComponentsCharacteristicsToExportHeaders';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  regexDate = /^\d{2}\/\d{2}\/\d{4}$/;

  private _offerImportLibrary: { [key: string]: string } = {
    'Nº Oferta': 'offerNumber',
    'Peticion de oferta': 'po',
    'Nombre Cliente': 'clientName',
    'Sigla cliente': 'clientAcronym',
    'Descripcion oferta': 'description',
    'Version documento': 'version',
    'Sigla preparado': 'whoPreparesInitialism',
    'Fecha preparado': 'preparationDate',
    'Sigla revisado': 'whoReviewsInitialism',
    'Fecha revisado': 'reviewDate',
    'Sigla aprobado': 'whoApprovesInitialism',
    'Fecha aprobado': 'approvalDate',
    'Nombre componente': 'name',
    Partida: 'item',
    'Material Cliente': 'customerMaterial',
    Modelo: 'model',
    Fabricante: 'producer',
    Distribuidor: 'distributor',
    Cantidad: 'quantity',
    'Categoria del componente': 'categoryName',
    Seccion: 'section',
    Subseccion: 'subsection',
    'nombre cca': 'ccaName',
    cc: 'isCC',
    'Tipo verificación': 'verificationType',
    'Cantidad ensayada': 'quantityAssayed',
    'metodo aceptacion': 'acceptanceMethod',
    muestreo: 'samplingPlan',
    'procedimiento ensayo': 'testProcedure',
    'criterio aceptacion': 'acceptanceRequirements',
    'documentacion CA': 'documentationCA',
    'obs./Lab.': 'obsLab',
    comentarios: 'observations',
  };

  private _elementsGoToOffer: string[] = ['offerNumber', 'po', 'description'];
  private _elementsGoToClient: string[] = ['clientName', 'clientAcronym'];
  private _elementsGoToDocument: string[] = ['version'];
  private _elementsGoToSection: string[] = [
    'section',
    'subsection',
    'ccaName',
    'isCC',
    'verificationType',
    'quantityAssayed',
    'acceptanceMethod',
    'samplingPlan',
    'testProcedure',
    'acceptanceRequirements',
    'documentationCA',
    'obsLab',
    'observations',
  ];
  private _elementsGoToComponent: string[] = [
    'name',
    'item',
    'customerMaterial',
    'model',
    'producer',
    'distributor',
    'quantity',
    'categoryName',
  ];
  private _elementsGoToSignature: string[] = [
    'whoPreparesInitialism',
    'preparationDate',
    'whoReviewsInitialism',
    'reviewDate',
    'whoApprovesInitialism',
    'approvalDate',
  ];

  constructor() {}

  public exportexcelComponent(arrayToConvert: iExcelBody[]) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(
      this.jsonExcelParse(arrayToConvert)
    );
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `${arrayToConvert[0].componentName}.xlsx`);
  }

  private jsonExcelParse(array: iExcelBody[]) {
    const newJsonParsedArray: iExcelBodyToSend[] = array.map((element) => {
      return {
        'Nombre componente': element.componentName,
        'Categoría del componente': element.componentCategory,
        'Sigla preparado': element.whoPreparesInitialism,
        'Fecha preparado': element.preparationDate,
        'Sigla revisado': element.whoReviewsInitialism,
        'Fecha revisado': element.reviewDate,
        'Sigla aprobado': element.whoApprovesInitialism,
        'Fecha aprobado': element.approvalDate,
        Seccion: element.section,
        Subseccion: element.subsection,
        'Nombre CCA': element.name,
        CC: element.isCC ? 0 : 1,
        'Método aceptación': element.acceptanceMethod,
        'Tipo verificación': element.verificationType,
        Muestreo: element.samplingPlan,
        'Procedimiento ensayo': element.testProcedure,
        'Criterio aceptación': element.acceptanceRequirements,
        'Documentación CA': element.documentationCA,
        'Obs/Lab': element.obsLab,
        Comentarios: element.observations,
      };
    });

    return newJsonParsedArray;
  }

  public exportExcelOffer(arrayToConvert: iExcelOfferToExport[]) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(
      this.jsonExcelOfferParse(arrayToConvert)
    );
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, 'Ofertas.xlsx');
  }

  private jsonExcelOfferParse(array: iExcelOfferToExport[]) {
    const newJsonParsedArray: iExcelOfferToExportHeaders[] = array.map(
      (element) => {
        return {
          'Nº Oferta': element.offerNumber,
          'Peticion de oferta': element.po,
          'Nombre Cliente': element.clientName,
          'Sigla cliente': element.clientAcronym,
          'Descripcion oferta': element.description,
          'Version documento': element.version,
          'Sigla preparado': element.whoPreparesInitialism,
          'Fecha preparado': element.preparationDate,
          'Sigla revisado': element.whoReviewsInitialism,
          'Fecha revisado': element.reviewDate,
          'Fecha aprobado': element.approvalDate,
          'Sigla aprobado': element.whoApprovesInitialism,
        };
      }
    );

    return newJsonParsedArray;
  }

  public exportExcelOfferComponent(
    arrayToConvert: iExcelOfferComponentsToExport
  ) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(
      this.jsonExcelOfferComponentParse(arrayToConvert.offers)
    );

    const labs = XLSX.utils.json_to_sheet(
      this.jsonExcelLabsParse(arrayToConvert.labs)
    );

    XLSX.utils.book_append_sheet(workBook, workSheet, 'OfertasComponentes');
    XLSX.utils.book_append_sheet(workBook, labs, 'Laboratorios');
    XLSX.writeFile(workBook, 'OfertasComponentes.xlsx');
  }

  private jsonExcelOfferComponentParse(
    array: iExcelOfferComponentsToExport['offers']
  ) {
    const newJsonParsedArray: iExcelOfferComponentsToExportHeaders[] =
      array.map((element) => {
        return {
          'Nº Oferta': element.offerNumber,
          'Peticion de oferta': element.po,
          'Nombre Cliente': element.clientName,
          'Sigla cliente': element.clientAcronym,
          'Descripcion oferta': element.description,
          'Version documento': element.version,
          'Sigla preparado': element.whoPreparesInitialism,
          'Fecha preparado': element.preparationDate,
          'Sigla revisado': element.whoReviewsInitialism,
          'Fecha revisado': element.reviewDate,
          'Sigla aprobado': element.whoApprovesInitialism,
          'Fecha aprobado': element.approvalDate,
          'Nombre componente': element.componentName,
          Partida: element.item,
          'Material Cliente': element.customerMaterial,
          Modelo: element.model,
          Fabricante: element.producer,
          Distribuidor: element.distributor,
          Cantidad: element.quantity,
          'Categoria del componente': element.category,
        };
      });

    return newJsonParsedArray;
  }
  private jsonExcelLabsParse(array: iExcelOfferComponentsToExport['labs']) {
    const newJsonParsedArray = array.map((element) => {
      return {
        Componente: element.component,
        'Partida componente': element.item,
        Laboratorio: element.name,
      };
    });

    return newJsonParsedArray;
  }

  public exportExcelOfferComponentCharacteristics(
    arrayToConvert: iExcelOfferComponentsCharacteristicsToExport
  ) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(
      this.jsonExcelOfferComponentCharacteristicsParse(arrayToConvert.offers)
    );

    const labs = XLSX.utils.json_to_sheet(
      this.jsonExcelLabsParse(arrayToConvert.labs)
    );

    XLSX.utils.book_append_sheet(workBook, workSheet, 'OfertasComponentes');
    XLSX.utils.book_append_sheet(workBook, labs, 'Laboratorios');
    XLSX.writeFile(workBook, 'OfertasComponentesCaracteristicas.xlsx');
  }

  private jsonExcelOfferComponentCharacteristicsParse(
    array: iExcelOfferComponentsCharacteristicsToExport['offers']
  ) {
    const newJsonParsedArray: iExcelOfferComponentsCharacteristicsToExportHeaders[] =
      array.map((element) => {
        return {
          'Nº Oferta': element.offerNumber,
          'Peticion de oferta': element.po,
          'Nombre Cliente': element.clientName,
          'Sigla cliente': element.clientAcronym,
          'Descripcion oferta': element.description,
          'Version documento': element.version,
          'Sigla preparado': element.whoPreparesInitialism,
          'Fecha preparado': element.preparationDate,
          'Sigla revisado': element.whoReviewsInitialism,
          'Fecha revisado': element.reviewDate,
          'Sigla aprobado': element.whoApprovesInitialism,
          'Fecha aprobado': element.approvalDate,
          'Nombre componente': element.componentName,
          Partida: element.item,
          'Material Cliente': element.customerMaterial,
          Modelo: element.model,
          Fabricante: element.producer,
          Distribuidor: element.distributor,
          Cantidad: element.quantity,
          'Categoria del componente': element.category,
          Seccion: element.section,
          Subseccion: element.subsection,
          'nombre cca': element.fieldName,
          cc: element.cc,
          'Tipo verificación': element.verificationType,
          'Cantidad ensayada': element.quantityAssayed,
          'metodo aceptacion': element.acceptanceMethod,
          muestreo: element.samplingPlan,
          'procedimiento ensayo': element.testProcedure,
          'criterio aceptacion': element.acceptanceRequirements,
          'documentacion CA': element.documentationCA,
          'obs./Lab.': element.obsLab,
          comentarios: element.observations,
        };
      });

    return newJsonParsedArray;
  }

  // Import Excel

  public importExcelComponents(file: any) {
    return new Promise<iExcelBodyToImport[]>((res, rej) => {
      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = this.arrayBufferToBinaryString(
          e.target.result
        );
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {
          type: 'binary',
          cellDates: true,
        });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data: iExcelBodyToImport[] = XLSX.utils.sheet_to_json(ws);
        if (!data) rej('No data found');
        res(data);
      };
    });
  }

  private arrayBufferToBinaryString(buffer: ArrayBuffer) {
    const uintArray = new Uint8Array(buffer);
    const charArray = Array.from(uintArray, (byte) =>
      String.fromCharCode(byte)
    );
    return charArray.join('');
  }

  public importExcelOffer(file: any) {
    return new Promise<iExcelOfferToImport[]>((res, rej) => {
      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = this.arrayBufferToBinaryString(
          e.target.result
        );
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {
          type: 'binary',
          cellDates: true,
        });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data: iExcelOfferToImport[] = XLSX.utils.sheet_to_json(ws);

        if (!data) rej('No data found');
        if (!IExcelOfferToImport.validateExcelData(data))
          rej(
            'El fichero introducido no corresponde con la opción seleccionada'
          );
        res(data);
      };
    });
  }

  public importExcelOfferComponents(file: any) {
    return new Promise<iExcelOfferComponents>((res, rej) => {
      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = this.arrayBufferToBinaryString(
          e.target.result
        );
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {
          type: 'binary',
          cellDates: true,
        });

        const data: iExcelOfferComponents = {
          offerAndComponent: XLSX.utils.sheet_to_json(
            wb.Sheets[wb.SheetNames[0]]
          ),
          lab: XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]]),
        };
        if (!data) rej('No data found');
        if (!IExcelOfferComponents.validateExcelOfferComponents(data))
          rej(
            'El fichero introducido no corresponde con la opción seleccionada'
          );
        res(data);
      };
    });
  }

  public importExcelOfferComponentsCharacteristics(file: any) {
    return new Promise<iExcelOfferComponentsCharacteristics>((res, rej) => {
      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = this.arrayBufferToBinaryString(
          e.target.result
        );
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {
          type: 'binary',
          cellDates: true,
        });

        const data: iExcelOfferComponentsCharacteristics = {
          offerComponentsCharacteristics: XLSX.utils.sheet_to_json(
            wb.Sheets[wb.SheetNames[0]]
          ),
          lab: XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]]),
        };
        if (!data) rej('No data found');
        if (
          !IExcelOfferComponentsCharacteristics.validateExcelOfferComponents(
            data
          )
        )
          rej(
            'El fichero introducido no corresponde con la opción seleccionada'
          );
        res(data);
      };
    });
  }

  public mountObjectOfferImport(data: iExcelOfferToImport[]) {
    return this.disgregateOfferComponent(data);
  }

  public mountObjectOfferComponentImport(data: iExcelOfferComponents) {
    const parsedOfferComponents: iExcelOfferImportToSend[] =
      this.disgregateOfferComponent(data.offerAndComponent);

    const offerWithConmponentsAndLabs = this.handleLabsForComponents(
      parsedOfferComponents,
      data.lab
    );

    const mountedOfferComponents = this.groupAndSumItems(
      offerWithConmponentsAndLabs
    );
    return mountedOfferComponents;
  }

  public mountObjectOfferComponentCharacteristicsImport(
    data: iExcelOfferComponentsCharacteristics
  ) {
    const parsedOfferComponentsCharacteristics: iExcelOfferImportToSend[] =
      this.disgregateOfferComponent(data.offerComponentsCharacteristics);

    const offerWithConmponentsAndLabs = this.handleLabsForComponents(
      parsedOfferComponentsCharacteristics,
      data.lab
    );

    const mountedOfferComponents = this.groupAndSumItems(
      offerWithConmponentsAndLabs
    );

    const mountedComponents = this.mountComponents(mountedOfferComponents);

    const mountSectionsSubsectionsAndCCAS =
      this.transformData(mountedComponents);
    return mountSectionsSubsectionsAndCCAS;
  }

  private mountComponents(offerComponents: iExcelOfferImportToSend[]) {
    let offers: iExcelOfferImportToSend['component'] = [];
    offerComponents.forEach((offerComponent, index) => {
      offerComponent.component!.forEach((component) => {
        const offerIndex = offers.findIndex(
          (offer) => offer.item === component.item
        );
        if (offerIndex === -1) {
          offers.push(component);
        } else {
          offers[offerIndex].sections = [
            ...(offers[offerIndex].sections || []),
            ...(component.sections || []),
          ];
        }
      });
      offerComponents[index].component = offers;
    });
    return offerComponents;
  }

  private getOfferImportLibrary() {
    return this._offerImportLibrary;
  }

  private disgregateOfferComponent(
    data:
      | iExcelOfferToImport[]
      | iExcelOfferComponentsCharacteristics['offerComponentsCharacteristics']
  ): iExcelOfferImportToSend[] {
    let objectArrayToSend: iExcelOfferImportToSend[] = [];
    data.forEach(
      (
        offer:
          | iExcelOfferToImport
          | iExcelOfferComponentsCharacteristics['offerComponentsCharacteristics'][0]
      ) => {
        let objectToSend: iExcelOfferImportToSend = {
          offer: {},
          client: {},
          document: {},
          signature: {},
          component: [],
        };
        let component: any = {};
        let section: any = {};
        Object.keys(offer).forEach((key) => {
          const newKey =
            this.getOfferImportLibrary()[key as keyof iExcelOfferImportToSend];
          if (this._elementsGoToOffer.includes(newKey)) {
            objectToSend.offer[newKey] = offer[key];
          } else if (this._elementsGoToDocument.includes(newKey)) {
            objectToSend.document[newKey] = offer[key];
          } else if (this._elementsGoToSignature.includes(newKey)) {
            objectToSend.signature[newKey] = this.transformSignatureValue(
              newKey,
              offer[key]
            );
          } else if (this._elementsGoToClient.includes(newKey)) {
            objectToSend.client[newKey] = offer[key];
          } else if (this._elementsGoToComponent.includes(newKey)) {
            component[newKey] = offer[key];
          } else if (this._elementsGoToSection.includes(newKey)) {
            section[newKey] = offer[key];
          }
        });
        const isFullComponent: boolean = this.hasDataObject(component);
        const isFullSection: boolean = this.hasDataObject(section);
        if (isFullComponent) {
          component.sections = component.sections || [];
          if (isFullSection) {
            component.sections.push(section);
          }
          if (component.sections.length === 0) delete component.sections;
          objectToSend.component?.push({
            ...component,
            categoryColor: this.getRandomCategoryColor(),
          });
        }
        objectArrayToSend.push(objectToSend);
      }
    );

    objectArrayToSend.forEach((object) => {
      if (object.component?.length === 0) delete object.component;
    });
    return objectArrayToSend;
  }

  private transformSignatureValue(newKey: string, value: any): any {
    if (newKey.includes('Date') && value) {
      return iDate.javascriptConvert(new Date(value)).toStringDate('JAP');
    }
    return value;
  }

  private hasDataObject(component: any): boolean {
    return Object.keys(component).length > 0;
  }

  private handleLabsForComponents(
    parsedOfferComponents: iExcelOfferImportToSend[],
    lab: iExcelOfferComponents['lab']
  ) {
    parsedOfferComponents.forEach((offerComponent, offerComponentIndex) => {
      offerComponent.component?.forEach((component, componentIndex) => {
        const componentLab = lab.filter(
          (l) =>
            l.Componente === component.name &&
            l['Partida componente']! == component.item!
        );
        if (componentLab) {
          parsedOfferComponents[offerComponentIndex].component![
            componentIndex
          ].lab = [
            ...(parsedOfferComponents[offerComponentIndex].component![
              componentIndex
            ].lab || []),
            ...componentLab.map((lab) => {
              return { name: lab.Laboratorio };
            }),
          ];
        }
      });
    });
    return parsedOfferComponents;
  }

  private groupAndSumItems(
    items: iExcelOfferImportToSend[]
  ): iExcelOfferImportToSend[] {
    const groupedItems = items.reduce((acc, item) => {
      if (!item.offer.offerNumber) return acc;
      const key = item.offer.offerNumber;
      if (!acc[key]) {
        acc[key] = { ...item };
        return acc;
      }
      acc[key].component = [
        ...(acc[key].component ?? []),
        ...(item.component ?? []),
      ];
      return acc;
    }, {} as { [key: string]: iExcelOfferImportToSend });

    return Object.values(groupedItems);
  }

  private getRandomCategoryColor(): string {
    const colors = StaticDataHandlerService.getColorsForCategories();
    return colors[Math.floor(Math.random() * colors.length)];
  }

  private sectionHandler(section: Section) {
    let newSection = { ...section };
    newSection.children = newSection.children.map((child: any) => {
      if (child.type === 'section') {
        return this.sectionHandler(child);
      } else {
        return {
          ...child,
          isOpen: false,
        };
      }
    });
    return newSection;
  }

  transformData(input: any[]): OutputStructure[] {
    return input.map((data) => {
      const transformedComponents = data.component.map((component: any) => {
        // Map para almacenar secciones y subsecciones
        const sectionMap: { [key: string]: SectionOutput } = {};
        const subsectionMap: { [key: string]: SubSection } = {};

        component.sections.forEach((section: any) => {
          const sectionKey = section.section;

          // Si la sección aún no está en el mapa, agregarla
          if (!sectionMap[sectionKey]) {
            sectionMap[sectionKey] = {
              section: section.section,
              type: 'section',
              children: [],
            };
          }

          const sectionNode = sectionMap[sectionKey];

          if (section.subsection) {
            // Crear clave para subsección
            const subsectionKey = `${sectionKey}-${section.subsection}`;

            // Si la subsección aún no está en el mapa, agregarla
            if (!subsectionMap[subsectionKey]) {
              subsectionMap[subsectionKey] = {
                subsection: section.subsection,
                type: 'subsection',
                children: [],
              };
              sectionNode.children.push(subsectionMap[subsectionKey]);
            }

            // Agregar el CCA a la subsección
            subsectionMap[subsectionKey].children.push({
              type: 'cca',
              ccaName: section.ccaName,
              isCC: section.isCC,
              verificationType: section.verificationType,
              quantityAssayed: section.quantityAssayed,
              acceptanceMethod: section.acceptanceMethod,
              samplingPlan: section.samplingPlan,
              testProcedure: section.testProcedure,
              acceptanceRequirements: section.acceptanceRequirements,
              documentationCA: section.documentationCA,
              obsLab: section.obsLab,
            });
          } else {
            // Agregar el CCA directamente a la sección
            sectionNode.children.push({
              type: 'cca',
              ccaName: section.ccaName,
              isCC: section.isCC,
              verificationType: section.verificationType,
              quantityAssayed: section.quantityAssayed,
              acceptanceMethod: section.acceptanceMethod,
              samplingPlan: section.samplingPlan,
              testProcedure: section.testProcedure,
              acceptanceRequirements: section.acceptanceRequirements,
              documentationCA: section.documentationCA,
              obsLab: section.obsLab,
            });
          }
        });

        return {
          ...component,
          sections: Object.values(sectionMap),
        };
      });

      return {
        ...data,
        component: transformedComponents,
      };
    });
  }

  private dateHandler(date: Date | null): string | null {
    if (!date) return null;
    return iDate.javascriptConvert(new Date(date)).toStringDate('EU');
  }
}

type SectionToMount = {
  section: string;
  subsection?: string;
  ccaName: string;
  isCC: number;
  verificationType?: string;
  quantityAssayed?: number;
  acceptanceMethod?: number;
  samplingPlan?: string;
  testProcedure?: string;
  acceptanceRequirements?: string;
  documentationCA?: string;
  obsLab?: string;
};

type Component = {
  name: string;
  item: number;
  customerMaterial: string;
  model: string;
  producer: string;
  distribuitor: string;
  quantity: number;
  categoryName: string;
  sections: SectionToMount[];
  categoryColor: string;
  lab?: { name: string }[];
};

type Offer = {
  offerNumber: string;
  po: string;
  description: string;
};

type Client = {
  clientName: string;
  clientAcronym: string;
};

type Document = {
  version: number;
};

type Signature = {
  whoPreparesInitialism: string;
  preparationDate: string;
  whoReviewsInitialism: string;
  reviewDate: string;
  whoApprovesInitialism: string;
  approvalDate: string;
};

type InputStructure = {
  offer: Offer;
  client: Client;
  document: Document;
  signature: Signature;
  component: Component[];
};

type CCA = Omit<SectionToMount, 'section' | 'subsection'> & { type: 'cca' };
type SubSection = {
  subsection: string;
  type: 'subsection';
  children: CCA[];
};
type SectionOutput = {
  section: string;
  type: 'section';
  children: (SubSection | CCA)[];
};

type ComponentOutput = Omit<Component, 'sections'> & {
  sections: SectionOutput[];
};

type OutputStructure = Omit<InputStructure, 'component'> & {
  component: ComponentOutput[];
};
