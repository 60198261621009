export interface iExcelOfferComponents {
  offerAndComponent: OfertaAndComponent[];
  lab: Lab[];
}

interface OfertaAndComponent {
  [key: string]: string | number;
  'Nº Oferta': string;
  'Peticion de oferta': string;
  'Nombre Cliente': string;
  'Sigla cliente': string;
  'Descripcion oferta': string;
  'Version documento': number;
  'Sigla preparado': string;
  'Fecha preparado': string;
  'Sigla revisado': string;
  'Fecha revisado': string;
  'Sigla aprobado': string;
  'Fecha aprobado': string;
  'Nombre componente': string;
  Partida: number;
  'Material Cliente': string;
  Modelo: string;
  Fabricante: string;
  Distribuidor: string;
  Cantidad: number;
  'Categoria del componente': string;
}

export interface Lab {
  Componente: string;
  'Partida componente': string | null;
  Laboratorio: string;
}

export class IExcelOfferComponents {
  static isValidOfertaAndComponent(offer: any): offer is OfertaAndComponent {
    const requiredKeys = ['Nº Oferta', 'Nombre componente'];

    for (const key of requiredKeys) {
      if (!(key in offer)) {
        return false;
      }
    }

    let isValid = true;
    Object.keys(offer).forEach((offerKey) => {
      if (offerKey === 'nombre cca') {
        isValid = false;
        return;
      }
    });
    return isValid;
  }

  static isValidLab(lab: any): lab is Lab {
    const requiredKeys = ['Componente', 'Partida componente', 'Laboratorio'];

    for (const key of requiredKeys) {
      if (!(key in lab)) {
        return false;
      }
    }

    return true;
  }

  static validateExcelOfferComponents(
    data: any
  ): data is iExcelOfferComponents {
    if (!Array.isArray(data.offerAndComponent) || !Array.isArray(data.lab)) {
      return false;
    }

    const isValidOfferAndComponentArray = data.offerAndComponent.every(
      IExcelOfferComponents.isValidOfertaAndComponent
    );
    const isValidLabArray = data.lab.every(IExcelOfferComponents.isValidLab);

    return isValidOfferAndComponentArray && isValidLabArray;
  }
}
